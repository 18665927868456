import React, { ChangeEvent, useRef, useState, ComponentProps } from 'react';
import styled, { css } from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { PlusIcon, CloseXIcon, LeftArrowIcon } from '@lower-financial/icons';
import {
  formatFeatureToString,
  FormattedGoogleAddress,
  FormRow,
  getAddressByPlaceId,
  getFormattedAddressFromPlaceResult,
  Label,
  LottieSource,
  OptionalElement,
  Paragraph,
  useAddressAutocomplete,
  useModal,
  useOnClickOutside,
} from '@lower-financial/core-components';
// NOTE: TextInput is `undefined` when imported from `@lower-financial/core-components`
import { TextInput } from '@lower-financial/core-components/src/components/text-input/text-input';
import { theme } from '@lower-financial/core-components/src/styles/primary/theme';
import { IconButton } from '@lower-financial/core-components/src/components/icon-button/icon-button';
import { ThemedProps } from '@lower-financial/core-components/src/styles/utils/theme-utils';
import PoweredByGoogle from './powered_by_google_on_white.png';
import LoadingSpinner from './loading-spinner.json';

export function AddressAutocompleteWrapper({
  children, id, label = 'Address*',
}: { children: React.ReactNode; id: string; label?: string }) {
  return (
    <FormRow>
      <AutoCompleteLabelWrapper>
        <Label htmlFor={id}>
          {label}
        </Label>
        <AutoCompleteInputWrapper>
          {children}
        </AutoCompleteInputWrapper>
      </AutoCompleteLabelWrapper>
    </FormRow>
  );
}

export function doesContainUndefinedFeatures(predictions: FormattedGoogleAddress) {
  if (predictions
      && predictions.streetNumber === undefined
      || predictions.streetName === undefined
      || predictions.city === undefined
      || predictions.state === undefined
      || predictions.zipcode === undefined
      || predictions.county === undefined) {
    return true;
  } return false;
}

export type AddressAutocompleteOnChange = (result: FormattedGoogleAddress|null) => void;

interface AddressAutocompleteProps {
  fallbackUi: React.ReactNode;
  id: string;
  initialSearchText: string;
  setShowFallback: React.Dispatch<React.SetStateAction<boolean>>;
  showFallback: boolean;
  onChange: AddressAutocompleteOnChange;
  placeholder: string;
  WrappingElement: React.ComponentType<{ children: React.ReactNode; id: string; }>;
  fireAnalyticsEvent: (event: string) => void;
  disabled?: boolean;
  apiKey: string;
  appEnv: string;
}

export function AddressAutocomplete({
  placeholder,
  onChange,
  fallbackUi,
  initialSearchText,
  WrappingElement,
  setShowFallback,
  showFallback,
  id,
  fireAnalyticsEvent,
  disabled = false,
  apiKey,
  appEnv,
}: AddressAutocompleteProps) {
  const [searchText, setSearchText] = useState<string>(initialSearchText);
  const [loading, results] = useAddressAutocomplete(searchText, apiKey, appEnv, 200);
  const [shouldShow, show, hide] = useModal();
  const searchWrapperRef = useRef<HTMLDivElement|null>(null);
  const searchInputRef = useRef<HTMLInputElement|null>(null);
  const isDesktop = useMediaQuery({
    query: `(min-width: ${theme.breakpoints[0]})`,
  });

  const shouldDisplayInModal = shouldShow && !isDesktop;

  // "Fixes" issue with iOS soft keyboard pushing `position: fixed` out of the viewport
  // by scrolling to the input element if it is out of view
  const scrollToInput = () => {
    setTimeout(() => {
      if (isDesktop || !searchInputRef.current) {
        return;
      }

      const { top } = searchInputRef.current.getBoundingClientRect();

      if (top < 0) {
        window.scrollBy(0, top);
      }
    }, 60);
  };

  // this is used on desktop to detect clicks outside the wrapper element
  useOnClickOutside(searchWrapperRef, () => {
    hide();
  });

  return (
    <>
      <OptionalElement show={!showFallback}>
        <WrappingElement id={id}>
          <SearchWrapper
            ref={searchWrapperRef}
            $modal={shouldDisplayInModal}
          >
            <SearchTextInputWrapper $focused={shouldShow}>
              <OptionalElement show={shouldDisplayInModal}>
                <IconButton
                  label={'Exit address input'}
                  onClick={() => hide()}
                >
                  <LeftArrowIcon color={'var(--primary)'} />
                </IconButton>
              </OptionalElement>
              <SearchInputWrapper>
                <SearchTextInput
                  autoComplete={'off'}
                  id={id}
                  placeholder={placeholder}
                  ref={searchInputRef}
                  $isDisplayingInModal={shouldDisplayInModal}
                  value={searchText}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    fireAnalyticsEvent('searched_for_address');
                    setSearchText(e.target.value);
                    onChange(null);
                  }}
                  onFocus={() => {
                    show();
                    scrollToInput();
                  }}
                  disabled={disabled}
                />
              </SearchInputWrapper>
              <OptionalElement show={searchText !== ''}>
                <ClearSearchButton>
                  <IconButton
                    label={'Clear address'}
                    onClick={() => {
                      setSearchText('');
                      onChange(null);
                      searchInputRef.current?.focus();
                    }}
                  >
                    <CloseXIcon color={'var(--satin)'} />
                  </IconButton>
                </ClearSearchButton>
              </OptionalElement>
            </SearchTextInputWrapper>

            <OptionalElement show={shouldShow}>
              <ResultsWrapper data-dd-privacy={'mask'}>
                <OptionalElement show={loading}>
                  <SpinnerWrapper>
                    <LottieSource
                      lottieJSONFile={LoadingSpinner}
                      style={{
                        height: '22px',
                        width: '22px',
                      }}
                    />
                    <Paragraph variant={'light'}>
                      Searching for your address
                    </Paragraph>
                  </SpinnerWrapper>
                </OptionalElement>
                <OptionalElement show={!loading}>
                  {results.map((r) => (
                    <PropertyButton
                      onClick={() => {
                        (async () => {
                          fireAnalyticsEvent('selected_autocomplete_result');
                          setSearchText(r.description);
                          if (r.place_id) {
                            await getAddressByPlaceId(r.place_id, (result) => {
                              if (!result) {
                                return;
                              }
                              const address = getFormattedAddressFromPlaceResult(result);
                              setSearchText(formatFeatureToString(address) ?? '');
                              onChange(address);
                              hide();
                            }, apiKey, appEnv);
                          }
                        })();
                      }}
                      key={`${r.description}`}
                      data-dd-action-name={'Address autocomplete result'}
                    >
                      {r.description}
                    </PropertyButton>
                  ))}
                </OptionalElement>
                <OptionalElement show={!isDesktop || (isDesktop && !loading)}>
                  <AddManuallyButton
                    onClick={() => {
                      fireAnalyticsEvent('manual_address_entry_clicked');
                      setShowFallback(true);
                      onChange(null);
                    }}
                    aria-label={'Add Address Manually'}
                  >
                    <PlusIcon
                      color={'var(--primary)'}
                      alt={'plus'}
                    />&nbsp;Add Address Manually
                  </AddManuallyButton>
                </OptionalElement>
                <OptionalElement show={results.length > 0 && !loading}>
                  <AttributionImageWrapper>
                    <img
                      src={PoweredByGoogle}
                      alt={'Powered by Google'}
                    />
                  </AttributionImageWrapper>
                </OptionalElement>
              </ResultsWrapper>
            </OptionalElement>
          </SearchWrapper>
        </WrappingElement>
      </OptionalElement>
      <OptionalElement show={showFallback}>
        {fallbackUi}
      </OptionalElement>
    </>
  );
}

type SearchTextInputProps = {
  $isDisplayingInModal: boolean;
} & ComponentProps<typeof TextInput>;

const SearchTextInputWrapper = styled.div<{ $focused: boolean }>`
  display: flex;
  align-items: center;
  ${(props) => props.$focused && css`
    padding: 0 12px;
  `}
`;

const SearchTextInput = styled(TextInput)<SearchTextInputProps>`
  ${(props) => props.$isDisplayingInModal && css`
    border: none;
  `}
 
  text-overflow: ellipsis;
  padding-right: var(--spacing-12);
`;

const PropertyButton = styled.button`
all: unset;
font: var(--font-paragraph-4);
color: var(--body);
padding: 12px 0;
cursor: pointer;
${(props: ThemedProps) => props.theme.media.desktop} {
  padding: 12px;
  font: var(--font-paragraph-2);
}
`;

const AddManuallyButton = styled.button`
  display: flex;
  align-items: center;
  font: var(--font-paragraph-2);
  color: var(--body);
  padding: 12px 0;
  margin-top: auto;
  ${(props: ThemedProps) => props.theme.media.desktop} {
    padding: 12px;
  }
  > svg, > img {
    margin-right: 8px;
  }
`;

interface SearchWrapperProps {
  $modal: boolean;
}

const SearchInputWrapper = styled.div({
  flex: 1,
});

const ClearSearchButton = styled.div({});

const SearchWrapper = styled.div<SearchWrapperProps>`
position: relative;
z-index: var(--index-autocomplete-wrapper);

& ${ClearSearchButton} {
  position: absolute;
  right: 8px;
}

  ${(props) => props.$modal && css`
    background-color: var(--background);
    position: fixed;
    min-height: var(--layout-min-height);
    min-width: 100%;
    top: 0;
    left: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;

    & ${ClearSearchButton} {
      position: relative;
    }
  `}
`;

const ResultsWrapper = styled.div`
display: flex;
flex-grow: 1;
flex-direction: column;
background: var(--background);
width: 100%;
padding: 16px 24px;

${(props: ThemedProps) => props.theme.media.desktop} {
  flex-grow: 0;
  position: absolute;
  top: calc(100% + 12px);
  z-index: 1000;

  border: 1px solid var(--form-field-border);
  border-radius: 12px;

  padding: 16px;
}
`;
const AutoCompleteLabelWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;
`;

const AutoCompleteInputWrapper = styled.div`
  flex: 1;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;

  ${(props: ThemedProps) => props.theme.media.desktop} {
    flex-direction: row;
    justify-content: flex-start;
  }
`;

const AttributionImageWrapper = styled.div`
  justify-content: flex-end;
  display: flex;
`;
