import { useErrorHandling, useProgress } from '@lower-financial/core-components';
import { useCallback, useEffect, useState } from 'react';
import { useAnalytics } from '@lower-financial/analytics';
import { useNextRoute } from '@lightspeed/routing/useNextRoute';
import { ProcessingTemplate } from '@lightspeed/components/ui/pages/processing/processing-template';
import { pollForJumpstartEligibility } from '@lightspeed/utils/poll-for-jumpstart-eligibility';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';

export function JumpstartProcessing() {
  const { mortgageApplication, updateMortgageApplication } = useMortgageApplication();
  const { goToNextRoute } = useNextRoute();
  const handleError = useErrorHandling();
  const [shouldGoToNextRoute, setShouldGoToNextRoute] = useState(false);
  const fireAnalyticsEvent = useAnalytics('jumpstart_processing');

  useEffect(() => {
    if (shouldGoToNextRoute) {
      fireAnalyticsEvent('completed');
      goToNextRoute();
      setShouldGoToNextRoute(false);
    }
  }, [fireAnalyticsEvent, goToNextRoute, shouldGoToNextRoute]);

  const completeAsyncAction = useCallback(() => {
    setShouldGoToNextRoute(true);
  }, [setShouldGoToNextRoute]);

  const asyncAction = async () => {
    await handleError({
      tryFn: async () => {
        if (mortgageApplication.quotingApplicationSubmissionId && mortgageApplication.loanPurpose) {
          const eligibilityResponse = await pollForJumpstartEligibility(
            mortgageApplication.quotingApplicationSubmissionId,
            fireAnalyticsEvent,
          );

          updateMortgageApplication('jumpstartEligibilityDecision', eligibilityResponse.decision);
        }
      },
    });
  };

  const textPrompts = [
    'Plugging in your numbers...',
    'Uploading income...',
    'Confirming identity...',
    'Running soft credit pull...',
  ];

  const [progress, prompt] = useProgress(
    textPrompts,
    asyncAction,
    completeAsyncAction,
    textPrompts.length * 2,
  );

  return (
    <ProcessingTemplate
      progress={progress}
      prompt={prompt}
    />
  );
}
