import { useMemo, useState } from 'react';
import { useAnalytics } from '@lower-financial/analytics';
import {
  Button,
  Explainer,
  LoadingModal,
  OptionalElement,
  Paragraph,
  useErrorHandling,
  useValidation,
} from '@lower-financial/core-components';
import { currencyFormatter, objectContainsFalsyPropsForKeys } from '@lower-financial/toolbox';
import { Header } from '@lightspeed/components/ui/molecules/header';
import { Page } from '@lightspeed/components/ui/templates/base-page-template/page';
import { Scoreboard } from '@lightspeed/components/ui/molecules/scoreboard/scoreboard';
import { BasePageTemplate } from '@lightspeed/components/ui/templates';
import { SplitSection } from '@lightspeed/components/ui/templates/split-section';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';
import {
  MortgageApplicationStoreKeys,
} from '@lightspeed/contexts/mortgage-application-context/use-mortgage-application-state';
import { useNextRoute } from '@lightspeed/routing/useNextRoute';
import {
  mortgageApplicationSchema,
} from '@lightspeed/contexts/mortgage-application-context/mortgage-application-validation';
import { useMortgageContextDefault } from '@lightspeed/hooks/useMortgageContextDefaults/useMortgageContextDefaults';
import { useUpsertApplication } from '@lightspeed/hooks/useUpsertApplication/useUpsertApplication';
import { calculateAssetsTotal } from '@lightspeed/utils/mortgage-application/calculate-stated-assets';
import { Layout } from '@lightspeed/components/ui/organisms/layout';
import { YourAssetsForm } from './your-assets-form';

const borrowerKeys: MortgageApplicationStoreKeys[] = [
  'borrowerAmountInSavings',
];

const coBorrowerKeys: MortgageApplicationStoreKeys[] = [
  'coBorrowerAmountInSavings',
];

export function YourAssets() {
  const { mortgageApplication } = useMortgageApplication();
  const { goToNextRoute } = useNextRoute();
  const fireAnalyticsEvent = useAnalytics('v2/assets');
  const { upsertApplication } = useUpsertApplication();
  const handleError = useErrorHandling();
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const { hasCoBorrower } = mortgageApplication;
  const totalAssets = useMemo(() =>
    currencyFormatter(calculateAssetsTotal(mortgageApplication)).slice(1), [mortgageApplication]);
  useMortgageContextDefault('borrowerAmountInMutualFunds');
  useMortgageContextDefault('coBorrowerAmountInMutualFunds', hasCoBorrower);
  useMortgageContextDefault('coBorrowerAmountInSavings', hasCoBorrower);

  const [validate, errors] = useValidation(mortgageApplicationSchema, {
    keysToValidate: hasCoBorrower
      ? [...borrowerKeys, ...coBorrowerKeys]
      : borrowerKeys,
    yupValidationContext: {
      hasCoBorrower,
    },
  });

  const disableNextButton = objectContainsFalsyPropsForKeys(
    mortgageApplication,
    hasCoBorrower
      ? [...borrowerKeys, ...coBorrowerKeys]
      : borrowerKeys,
  );

  const onNextClick = () => {
    (async () => {
      if (validate(mortgageApplication).success) {
        setShowLoadingModal(true);

        await handleError({
          onRetryableError: () => {
            setShowLoadingModal(false);
          },
          tryFn: async () => {
            await upsertApplication();
            setShowLoadingModal(false);
            fireAnalyticsEvent('next_button_success');
            goToNextRoute();
          },
        });
      }
    })();
  };

  return (
    <Layout>
      <OptionalElement show={showLoadingModal}>
        <LoadingModal />
      </OptionalElement>
      <Page
        desktop={(
          <>
            <Header />
            <BasePageTemplate>
              <SplitSection>
                <SplitSection.Content>
                  <YourAssetsForm
                    hasCoborrower={hasCoBorrower}
                    errors={errors}
                  />
                  <Explainer
                    label={'What to Include'}
                  >
                    <Paragraph variant={'smallLight'}>
                      Checking or savings, mutual funds, CDs, or readily tradable bonds or stocks that you can
                      easily convert to cash. Any money you have in accounts that could be pulled out as cash
                      should be listed.
                    </Paragraph>
                  </Explainer>
                </SplitSection.Content>
                <SplitSection.Sidebar >
                  <Scoreboard
                    marginY={'36px'}
                    label={'Total'}
                    prefix={'$'}
                    value={totalAssets}
                  />
                  <Button
                    marginBottom={'32px'}
                    disabled={disableNextButton}
                    onClick={onNextClick}
                  >
                    Next
                  </Button>
                </SplitSection.Sidebar>
              </SplitSection>
            </BasePageTemplate>
          </>
        )}

        mobile={(
          <>
            <Header />
            <BasePageTemplate>
              <div>
                <YourAssetsForm
                  hasCoborrower={hasCoBorrower}
                  errors={errors}
                />
                <Explainer
                  label={'What to Include'}
                >
                  <Paragraph variant={'smallLight'}>
                    Checking or savings, mutual funds, CDs, or readily tradable bonds or stocks that you can
                    easily convert to cash. Any money you have in accounts that could be pulled out as cash
                    should be listed.
                  </Paragraph>
                </Explainer>
                <Scoreboard
                  marginY={'32px'}
                  label={'Total'}
                  prefix={'$'}
                  value={totalAssets}
                />
              </div>

              <Button
                marginTop={'32px'}
                disabled={disableNextButton}
                onClick={onNextClick}
              >
                Next
              </Button>
            </BasePageTemplate>
          </>
        )}
      />
    </Layout>
  );
}
