import styled from 'styled-components';
import { responsiveStyleFromTheme } from '@lower-financial/core-components/src/styles/utils/theme-utils';
import { Form, FormRow, Label, PhoneInput, TextInput, ValidationError } from '@lower-financial/core-components';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';

interface AboutYouFormProps {
  errors: Map<string, string[]>|null;
  disabled: boolean;
}

export function AboutYouForm({ errors, disabled }: AboutYouFormProps) {
  const { mortgageApplication, updateMortgageApplication } = useMortgageApplication();

  return (
    <Form>
      <FormRow>
        <NameLabel>
          First Name
          <TextInput
            name={'borrowerFirstName'}
            value={mortgageApplication.borrowerFirstName}
            onChange={(e) => { updateMortgageApplication('borrowerFirstName', e.target.value); }}
            disabled={disabled}
          />
          <ValidationError
            path={'borrowerFirstName'}
            errors={errors}
          />
        </NameLabel>
        <NameLabel>
          Last Name
          <TextInput
            name={'borrowerLastName'}
            value={mortgageApplication.borrowerLastName}
            onChange={(e) => { updateMortgageApplication('borrowerLastName', e.target.value); }}
            disabled={disabled}
          />
          <ValidationError
            path={'borrowerLastName'}
            errors={errors}
          />
        </NameLabel>
      </FormRow>
      <FormRow>
        <ContactLabel>
          Email
          <TextInput
            $hasError={!!errors?.get('borrowerEmail')}
            inputMode={'email'}
            name={'borrowerEmail'}
            value={mortgageApplication.borrowerEmail}
            onChange={(e) => { updateMortgageApplication('borrowerEmail', e.target.value); }}
            placeholder={'me@example.com'}
            disabled={disabled}
          />
          <ValidationError
            path={'borrowerEmail'}
            errors={errors}
          />
        </ContactLabel>
        <ContactLabel>
          Phone
          <PhoneInput
            name={'borrower-phone-number'}
            value={mortgageApplication.borrowerPhoneNumber}
            onChange={(v) => updateMortgageApplication('borrowerPhoneNumber', v)}
            disabled={disabled}
          />
          <ValidationError
            path={'borrowerPhoneNumber'}
            errors={errors}
          />
        </ContactLabel>
      </FormRow>
    </Form>
  );
}

const NameLabel = styled(Label)`
  flex: 1;
`;

const ContactLabel = styled(Label)`
  flex: 0 0 100%;

  ${responsiveStyleFromTheme({
    desktop: {
      flex: '1',
    },
  })}
`;
