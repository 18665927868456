import styled, { keyframes } from 'styled-components';
import { useRef, useState, ComponentProps } from 'react';
import { CloseX, Play } from '@lower-financial/icons';
import { OptionalElement, useOnClickOutside } from '@lower-financial/core-components';

interface VideoModalComponentProps {
  videoSrc?: string;
  thumbnailImgSrc?: string;
  showModal: boolean;
  onClose: () => void;
  videoOptions?: VideoOptionProps;
}

interface VideoOptionProps {
  autoPlay?: boolean,
  controls?: boolean,
  loop?: boolean,
  muted?: boolean,
}

const defaultConfig = {
  autoPlay: true,
  controls: false,
  loop: false,
  muted: false,
};

export const VideoModal = ({
  videoSrc,
  thumbnailImgSrc,
  showModal = false,
  videoOptions = {},
  onClose,
  ...props
}: VideoModalComponentProps) => {
  const config = {
    ...defaultConfig,
    ...videoOptions,
  };

  const [videoIsPaused, setVideoIsPaused] = useState(false);
  const videoRef = useRef<HTMLVideoElement|null>(null);
  const videoWrapperRef = useRef<HTMLDivElement|null>(null);

  useOnClickOutside(videoWrapperRef, onClose);

  const playVideo = async () => {
    setVideoIsPaused(false);
    await videoRef.current?.play();
  };

  const pauseVideo = () => {
    if (!videoRef.current?.paused) {
      setVideoIsPaused(true);
      videoRef.current?.pause();
    }
  };

  if (videoSrc === null || thumbnailImgSrc === null) {
    return null;
  }

  return (
    <OptionalElement show={showModal}>
      <BlackOverlay
        data-testid={'video-modal'}
        aria-label={'Video modal'}
        role={'dialog'}
      >
        <PlayerContainer ref={videoWrapperRef}>
          <CloseModalButton
            data-testid={'close-button'}
            aria-label={'Close'}
            onClick={() => {
              setVideoIsPaused(false);
              onClose();
            }}
          >
            <CloseModalX src={CloseX} />
          </CloseModalButton>
          <OptionalElement show={videoIsPaused}>
            <PlayButton
              onClick={() => {
                (async () => {
                  await playVideo();
                })();
              }}
            >
              <img
                src={Play}
                alt={'Play video'}
              />
            </PlayButton>
          </OptionalElement>
          <VideoPlayer
            aria-label={'Video player'}
            data-testid={'video-player'}
            ref={videoRef}
            onClick={pauseVideo}
            {...config}
          >
            <source
              src={videoSrc}
              type={'video/mp4'}
            />
          </VideoPlayer>
        </PlayerContainer>
      </BlackOverlay>
    </OptionalElement>
  );
};

export type VideoModalProps = ComponentProps<typeof VideoModal>;

const FadeInKeyframes = keyframes`
  from {
    opacity: 0
  }
  to {
    opacity: 1;
  }
`;

const PlayerContainer = styled.div`
  position: relative;
  cursor: pointer;
  border-radius: 32px;
  box-shadow: 0 24px 48px var(--box-shadow);
  margin: 0px;
  padding: 0px 20px;
  max-width: 367px;
  opacity: 1;
  animation: ${FadeInKeyframes} 300ms ease-in-out;
`;

const VideoPlayer = styled.video`
  border-radius: 32px;
  width: 100%;
  max-width: 100%;
`;

const BlackOverlay = styled.div`
  position: fixed;
  z-index: 1000;
  background: var(--overlay);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  animation: ${FadeInKeyframes} 200ms ease-in-out;
`;

const CloseModalX = styled.img`
  position: absolute;
  right: 0px;
  top: 0px;
`;

const CloseModalButton = styled.button`
  all: unset;
  cursor: pointer;
  position: absolute;
  padding: 7px;
  right: 40px;
  top: 20px;
  z-index: 3000;
`;

const PlayButton = styled.button`
  all: unset;
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
`;
