import React, { HTMLAttributes } from 'react';
import cx from 'classnames';
import styled, { css } from 'styled-components';
import { compose, layout, LayoutProps, space, SpaceProps } from 'styled-system';
import { responsiveStyleFromTheme, ThemedProps } from '../../styles/utils/theme-utils';

export type ElementType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
type Size = 'xxl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs' | null;

export enum HeadingColors {
  Black ='var(--black)',
  Grey = 'var(--smoke)',
  Primary = 'var(--primary)',
  Error = 'var(--error)',
}

export interface HeadingProps extends HTMLAttributes<HTMLElement> {
  element?: ElementType;
  testId?: string;
  size?: Size;
  isSensitive?: boolean;
  color?: HeadingColors;
}

const defaultElementSize = {
  h1: 'xl',
  h2: 'lg',
  h3: 'md',
  h4: 'sm',
  h5: 'xs',
  h6: 'xs',
};

export const Heading = ({
  className,
  children,
  testId = 'heading',
  element = 'h1',
  size = null,
  isSensitive = false,
  color = HeadingColors.Black,
  ...otherProps
}: HeadingProps & LayoutProps & SpaceProps): React.ReactElement => (
  <Element
    as={element}
    className={cx(size ?? defaultElementSize[element], className)}
    data-test-id={testId}
    $color={color}
    {...otherProps}
    {...sensitiveAttributes(isSensitive)}
  >
    {children}
  </Element>
);

const sensitiveAttributes = (isSensitive: boolean) => (
  isSensitive
    ? {
      'data-dd-privacy': 'mask',
    }
    : {}
);

const Element = styled.p((props: ThemedProps & { $color: string }) => css` 
  text-wrap: balance;
  color: ${props.$color};

  &.xxl {
    ${ responsiveStyleFromTheme(props.theme.Headers.xxl) };
  }

  &.xl {
    ${ responsiveStyleFromTheme(props.theme.Headers.xl) };
  }

  &.lg {
    ${ responsiveStyleFromTheme(props.theme.Headers.lg) };
  }

  &.md {
    ${ responsiveStyleFromTheme(props.theme.Headers.md) };
  }

  &.sm {
    ${ responsiveStyleFromTheme(props.theme.Headers.sm) };
  }

  &.xs {
    ${ responsiveStyleFromTheme(props.theme.Headers.xs) };
  }

  ${
  compose(
    layout,
    space,
  )
}
`);

