import styled from 'styled-components';
import cx from 'classnames';
import { Button, ButtonComponentProps } from '../button/button';
import { LoadingIcon } from '../loading-icon/loading-icon';

type LoadingButtonProps = {
  label: string;
  isLoading: boolean;
  spinnerHeight?: string;
  ariaLabel?: string;
} & ButtonComponentProps;

// TODO: if we could use aria roles to signify that the page is now loading, that'd be great
export const LoadingButton = ({
  label, isLoading, spinnerHeight = 'var(--font-button-md-size)', ariaLabel, ...props
}: LoadingButtonProps) => (
  <LoadButton
    aria-label={ariaLabel ?? label}
    className={cx({
      loading: isLoading,
    })}
    {...props}
  >
    {isLoading
      ? (
        <StyledIcon
          spinnerHeight={spinnerHeight}
        />
      )
      : null}
    <LoadingLabel $isLoading={isLoading}>
      {label}
    </LoadingLabel>
  </LoadButton>
);

const StyledIcon = styled(LoadingIcon)`
  inset: 0;
  position: absolute;
`;

const LoadButton = styled(Button)`
  position: relative;
`;

const LoadingLabel = styled.span<{ $isLoading: boolean  }>(({ $isLoading }) => `
  visibility: ${$isLoading
    ? 'hidden'
    : 'visible'}
`);
