import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { LoadingModal, OptionalElement, useErrorHandling, useValidation } from '@lower-financial/core-components';
import { objectContainsFalsyPropsForKeys, slugify } from '@lower-financial/toolbox';
import { DidYouKnow } from '@lightspeed/components/shared/banners/did-you-know';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';
import { useNextRoute } from '@lightspeed/routing/useNextRoute';
import { useUpsertApplication } from '@lightspeed/hooks/useUpsertApplication/useUpsertApplication';
import { Layout } from '@lightspeed/components/ui/organisms/layout';
import {
  mortgageApplicationSchema,
} from '@lightspeed/contexts/mortgage-application-context/mortgage-application-validation';
import {
  MortgageApplicationStoreKeys,
} from '@lightspeed/contexts/mortgage-application-context/use-mortgage-application-state';
import { useMarket } from '@lightspeed/hooks/useMarket/useMarket';
import { useMortgageAnalytics } from '@lightspeed/hooks/useMortgageAnalytics/use-mortgage-analytics';
import { NextStepButton } from '@lightspeed/components/shared/buttons/next-step-button';
import { PageLayout } from '@lightspeed/components/shared/page-layout/page-layout';
import { PropertyStateForm } from '@lightspeed/components/ui/pages/property-state/property-state-form';
import { Testimonial } from '@lightspeed/components/ui/molecules/testimonial';
import { TestimonialSidebar } from '@lightspeed/components/shared/sidebars/testimonial-sidebar';

const propertyKeys: MortgageApplicationStoreKeys[] = [
  'propertyState',
];

const title = 'And whereabouts are you shopping?';
const subtitle = 'Making sure we have an advisor in your area. 👯';

export function PropertyState() {
  const { mortgageApplication } = useMortgageApplication();
  const { goToNextRoute } = useNextRoute();
  const handleError = useErrorHandling();
  const fireAnalyticsEvent = useMortgageAnalytics('property_state');

  const { upsertApplication } = useUpsertApplication();
  const [isLoading, setIsLoading] = useState(false);
  const { isUnsupportedMarket, isMarketLoading } = useMarket();

  const [validate, errors] = useValidation(mortgageApplicationSchema, {
    keysToValidate: propertyKeys,
  });

  const disableNextButton = isLoading || objectContainsFalsyPropsForKeys(
    mortgageApplication,
    propertyKeys,
  );

  const onNextClick = useCallback(
    () => {
      (async () => {
        if (!validate(mortgageApplication).success) {
          return;
        }

        setIsLoading(true);
        await handleError({
          onRetryableError: () => {
            setIsLoading(false);
          },
          tryFn: async () => {
            await upsertApplication();

            if (isUnsupportedMarket(mortgageApplication.propertyState)) {
              fireAnalyticsEvent(`bad_state_${slugify(mortgageApplication.propertyState)}`);
            } else {
              fireAnalyticsEvent('next_button_success');
            }
            goToNextRoute();
          },
        });
      })();
    },
    [
      fireAnalyticsEvent,
      handleError,
      goToNextRoute,
      isUnsupportedMarket,
      mortgageApplication,
      upsertApplication,
      validate,
    ],
  );

  const nextStepButton = useMemo(
    () => (
      <NextStepButton
        isLoading={isLoading}
        disabled={disableNextButton}
        onClick={onNextClick}
      />
    ),
    [disableNextButton, isLoading, onNextClick],
  );

  return (
    <Layout>
      <OptionalElement show={isMarketLoading}>
        <LoadingModal />
      </OptionalElement>
      <PageLayout
        title={title}
        subtitle={subtitle}
        button={nextStepButton}
        mobileBanner={<PropertyStateDidYouKnow />}
        desktopSidebar={(
          <TestimonialSidebar
            testimonial={<PropertyStateTestimonial />}
            banner={<PropertyStateDidYouKnow />}
          />
        )}
      >
        <PropertyStateForm
          isLoading={isLoading}
          errors={errors || new Map()}
        />
      </PageLayout>
    </Layout>
  );
}

const PropertyStateDidYouKnow = () => (
  <DidYouKnow>
    <HighlightedText>Where you’re shopping</HighlightedText> helps us find an expert
    in your area and narrow down your options.
  </DidYouKnow>
);

const PropertyStateTestimonial = () => (
  <Testimonial
    title={'Quick and simple closing!'}
    content={'Our advisor worked with me to find the best rates and payment plan in a rapidly changing and competitive market. He quickly answered any questions I had and made sure I understood all of the critical details of the process.'}
    author={'Nick'}
    date={'2022-05-26'}
  />
);

const HighlightedText = styled.strong`
  color: var(--tertiary-1);
`;
