import { OptionalElement } from '@lower-financial/core-components/src';
import { ReactNode } from 'react';
import styled from 'styled-components';

export function MobileButtonLayout({
  button,
  disclaimer,
}:
{
  button: ReactNode | ReactNode[],
  disclaimer?: ReactNode | ReactNode[],
}) {
  return (
    <MobileButtonContainer>
      <OptionalElement show={disclaimer !== undefined}>
        <MobileDisclaimer>
          {disclaimer}
        </MobileDisclaimer>
      </OptionalElement>
      {button}
    </MobileButtonContainer>
  );
}

const MobileButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MobileDisclaimer = styled.div`
  text-align: center;
`;
