import { ReactNode } from 'react';
import styled from 'styled-components';
import { responsiveStyleFromTheme } from '../../styles/utils/theme-utils';
import { Heading } from '../heading';
import { Paragraph } from '../paragraph';

export const CardButtonContent = ({
  title,
  subtitle,
  icon,
}: {
  title: string,
  subtitle: string,
  icon: ReactNode|ReactNode[],
}) => (
  <CardButtonContentContainer>
    <IconContainer>{icon}</IconContainer>
    <TextContainer>
      <Heading size={'xs'}>{title}</Heading>
      <StyledParagraph variant={'small'}>{subtitle}</StyledParagraph>
    </TextContainer>
  </CardButtonContentContainer>
);

const CardButtonContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  align-items: start;
  flex-grow: 2;
`;

const IconContainer = styled.div`
  font-size: 1.5rem;
  padding-top: 0.5rem;
  ${responsiveStyleFromTheme({
    desktop: {
      paddingTop: '0',
    },
  })}
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  ${responsiveStyleFromTheme({
    desktop: {
      marginBottom: 'auto',
      marginTop: 'auto',
      maxWidth: '50%',
    },
  })}
`;

const StyledParagraph = styled(Paragraph)`
  color: var(--ash);
`;
