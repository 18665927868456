import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';
import { SecurityFirst } from '@lightspeed/components/ui/pages/terminal-screens/security-first';
import { getMarketingUrl } from '@lightspeed/utils/get-marketing-url';

export const Submitted = () => {
  const { mortgageApplication } = useMortgageApplication();

  const closeButtonUrl = getMarketingUrl(mortgageApplication.loanPurpose);

  return (
    <SecurityFirst
      analyticsIdentifier={'lightspeed_submitted'}
      closeButtonUrl={closeButtonUrl}
    />
  );
};
