import { useAnalytics } from '@lower-financial/analytics';
import { SecureCallIcon } from '@lower-financial/icons/src/components/secure-call-icon';
import { TerminalScreenLayout } from '@lightspeed/components/shared/terminal-screen-layout/terminal-screen-layout';
import { OnCallVerificationBanner } from '@lightspeed/components/shared/banners/on-call-verification';

export function SecurityFirst({
  analyticsIdentifier,
  closeButtonUrl,
}:
{
  analyticsIdentifier: string,
  closeButtonUrl: string,
}) {
  const fireAnalyticsEvent = useAnalytics(analyticsIdentifier);

  return (
    <TerminalScreenLayout
      heading={<>All set! Keep your<br />information safe.</>}
      subtext={'We’ll be calling shortly from a number with a (614) area code—so you know it’s us.'}
      iconComponent={<SecureCallIcon />}
      fireAnalyticsEvent={fireAnalyticsEvent}
      banner={<OnCallVerificationBanner />}
      closeButtonUrl={closeButtonUrl}
    />
  );
}
