/* tslint:disable */
/* eslint-disable */
/**
 * Lower API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DesiredLoanGoal = {
    ChangeTerm: 'changeTerm',
    LowerPayment: 'lowerPayment',
    LowerRate: 'lowerRate',
    PayOffDebt: 'payOffDebt',
    TakeOutCash: 'takeOutCash',
    NotSure: 'notSure'
} as const;
export type DesiredLoanGoal = typeof DesiredLoanGoal[keyof typeof DesiredLoanGoal];


export function DesiredLoanGoalFromJSON(json: any): DesiredLoanGoal {
    return DesiredLoanGoalFromJSONTyped(json, false);
}

export function DesiredLoanGoalFromJSONTyped(json: any, ignoreDiscriminator: boolean): DesiredLoanGoal {
    return json as DesiredLoanGoal;
}

export function DesiredLoanGoalToJSON(value?: DesiredLoanGoal | null): any {
    return value as any;
}

