import styled from 'styled-components';
import { ReactElement, ReactNode } from 'react';

import { Banner, Heading, Paragraph } from '..';
import { responsiveStyleFromTheme } from '../../styles/utils/theme-utils';

export interface AlertBannerProps {
  icon: ReactElement;
  heading: ReactNode | ReactNode[];
  message: ReactNode | ReactNode[];
}

export const AlertBanner = ({
  icon, heading, message, ...props
}: AlertBannerProps) => (
  <Container {...props} >
    <HeadingRow>
      <IconContainer>
        {icon}
      </IconContainer>
      <Heading element={'h5'}>{heading}</Heading>
    </HeadingRow>
    <ParagraphContainer>
      <Paragraph variant={'small'}>{message}</Paragraph>
    </ParagraphContainer>
  </Container>
);

const Container = styled(Banner)`
  background: var(--tertiary-6-light);
  padding: 1.5rem;
  border-radius: 0;
  
  ${responsiveStyleFromTheme({
    desktop: {
      padding: '1rem 0 1rem 3rem',
    },
  })}
`;

const ParagraphContainer = styled.div`
  margin: 0 2rem 0 2rem;
`;

const IconContainer = styled.div({
  marginRight: '0.75rem',
});

const HeadingRow = styled.div({
  alignItems: 'center',
  display: 'flex',
  marginBottom: '0.5rem',
});
