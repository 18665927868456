import { Heading, OptionalElement, Paragraph, responsiveStyleFromTheme } from '@lower-financial/core-components/src';
import { ReactNode } from 'react';
import styled from 'styled-components';

export function ContentHeading({
  title,
  titleSize = 'xl',
  subtitle,
}: {
  title: ReactNode | ReactNode[],
  titleSize?: 'xl' | 'lg',
  subtitle?: ReactNode | ReactNode[]
}) {
  return (
    <>
      <ContentHeadingTitle
        element={'h1'}
        size={titleSize}
      >
        {title}
      </ContentHeadingTitle>
      <OptionalElement show={subtitle !== undefined}>
        <ContentHeadingSubtitle>
          <Paragraph variant={'light'}>
            {subtitle}
          </Paragraph>
        </ContentHeadingSubtitle>
      </OptionalElement>
    </>
  );
}

const ContentHeadingTitle = styled(Heading)`
  padding-top: var(--spacing-7);

  ${responsiveStyleFromTheme({
    desktop: {
      maxWidth: '580px',
      paddingTop: '0',
    },
  })}
`;

const ContentHeadingSubtitle = styled.div`
  margin: var(--spacing-2) 0 var(--spacing-6) 0;
`;
