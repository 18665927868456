import styled from 'styled-components';
import { compose, fontWeight, FontWeightProps, layout, LayoutProps, space, SpaceProps } from 'styled-system';
import React, { ComponentProps } from 'react';
import cx from 'classnames';
import { ThemedProps } from '../../styles/utils/theme-utils';
import { OverlayOnHover } from '../overlay-on-hover';
import { LoadingIcon } from '../loading-icon';
import { LoadingIconColor } from '../loading-icon/loading-icon';

export type ButtonComponentProps<TAs extends 'button' | 'a' | undefined = 'button'> = {
  as?: TAs | 'div';
  target?: TAs extends 'a' ? string : never;
  href?: TAs extends 'a' ? string : never;
  className?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  size?: ButtonSizes;
  type?: 'button' | 'submit' | 'reset';
  variant?: 'primary' | 'secondary' | 'link' | 'block-link';
  flex?: boolean;
  isLoading?: boolean;
} & FontWeightProps & LayoutProps & SpaceProps;

export enum ButtonSizes {
  Small = 'small',
  Regular = 'regular',
}

const TextWrapper = styled.div<{
  variant: ButtonComponentProps<'button'>['variant'],
  size: ButtonComponentProps<'button'>['size'],
  isLoading: boolean
}>`
  z-index: 1;
  position: relative;
  ${(props) => (props.isLoading
    ? 'visibility: hidden;'
    : '')}
  ${(props) => (props.variant === 'link'
    ? ''
    : props.size === 'small'
      ? 'padding: var(--spacing-button-vert-small-padding) var(--spacing-button-horz-small-padding);'
      : 'padding: var(--spacing-button-vert-padding) var(--spacing-button-horz-padding);')}
`;

export const Button = <TAs extends 'button' | 'a' | undefined = 'button'>({
  children, disabled, isLoading = false, ...props
}: ButtonComponentProps<TAs>) => {
  let color = '#ffffff';
  if (props.variant === 'secondary') {
    color = '#000000';
  }
  return (
    <InternalButton
      {...props}
      disabled={disabled || isLoading}
    >
      {isLoading && <LoadingOverlay buttonVariant={props.variant} />}
      <OverlayOnHover
        $opacity={props.variant === 'link'
          ? '0'
          : '0.1'}
        $color={color}
        $disabled={disabled || isLoading}
        data-testid={'overlayOnHover'}
      >
        <TextWrapper
          isLoading={isLoading}
          variant={props.variant}
          size={props.size}
        >
          {children}
        </TextWrapper>
      </OverlayOnHover>
    </InternalButton>
  );
};

const InternalButton = styled.button.attrs(({
  className,
  type = 'button',
  variant = 'primary',
  size = ButtonSizes.Regular,
  flex = false,
  ...props
}: ButtonComponentProps) => ({
  className: cx('Button', className, `--${variant}`, `--size-${size}`, {
    '--flex': flex,
    'is-disabled': props.disabled,
  }),
  type,
  variant,
  ...props,
}))<ButtonComponentProps>`
  /* Reset */
  all: unset;
  cursor: pointer;
  box-sizing: border-box;

  overflow: hidden;
  position: relative;
  ${ (props: ThemedProps) => props.theme.Button.bold };
  border-radius: var(--button-border-radius);
  box-shadow: ${ (props: ThemedProps) => props.theme.shadows.generic };
  text-align: center;

  &.--flex {
    flex: 1;
  }

  &.--primary {
    color: var(--white);
    background-color: var(--primary);
    box-shadow: 0 14px 36px var(--box-shadow);
    transition: background-color 0.2s ease-out;

    &:hover:not(:active):not(.is-disabled):not(:disabled) {
      background-color: var(--primary-hover);
    }
  }

  &.--secondary {
    color: var(--primary);
    background-color: var(--primary-light);
  }

  &.--link {
    display: inline;
    text-align: left;
    color: var(--primary);
    background-color: transparent;
    border-radius: 0;
    box-shadow: none;
    padding: 0;
    margin: 0;
  }

  &.--block-link {
    color: var(--primary);
    background-color: transparent;
    box-shadow: none;
  }

  &.is-disabled, &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  &:focus-visible {
    border: 1px solid;
    border-color: var(--primary);
  }

  ${ compose(layout, space, fontWeight) };
`;

const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LoadingOverlay = ({ buttonVariant = 'primary' }: { buttonVariant?: ComponentProps<typeof Button>['variant'] }) => {
  const colorMap = {
    'block-link': LoadingIconColor.Primary,
    link: LoadingIconColor.Primary,
    primary: LoadingIconColor.White,
    secondary: LoadingIconColor.Primary,
  };

  return (
    <LoadingContainer>
      <LoadingIcon
        color={colorMap[buttonVariant]}
        spinnerHeight={'1rem'}
      />
    </LoadingContainer>
  );
};

export type ButtonProps = ComponentProps<typeof Button>;
