import React, { ComponentProps, MouseEventHandler, ReactNode } from 'react';
import styled from 'styled-components';
import { responsiveStyleFromTheme } from '../../styles/utils/theme-utils';
import { Heading, HeadingColors } from '../heading';
import { IconCircle } from '../icon-circle';
import { Paragraph } from '../paragraph';
import { ModalOverlay } from './modal-overlay';
import { ModalNav } from './modal-nav';

export type ModalProps = ComponentProps<typeof Modal>;

export const Modal = ({
  isOpen,
  onCloseModal,
  onNavigateBack,
  children,
  fitContent = false,
}: {
  isOpen: boolean,
  onCloseModal: () => void,
  onNavigateBack?: () => void,
  children?: ReactNode | ReactNode[],
  fitContent?: boolean
}) => {
  const handleContainerClick: MouseEventHandler = (e) => {
    e.stopPropagation();
  };
  return (
    <ModalOverlay
      isOpen={isOpen}
      onClose={onCloseModal}
    >
      <Animation>
        <ModalContainer
          onClick={handleContainerClick}
          $fitContent={fitContent}
          data-testid={'modal-container'}
        >
          <ModalNav
            onBack={onNavigateBack}
            onClose={onCloseModal}
          />
          {children}
        </ModalContainer>
      </Animation>
    </ModalOverlay>
  );
};

const Animation = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  height: 100%;
  animation: modal-slide-in 0.5s ease-out;
  ${responsiveStyleFromTheme({
    desktop: {
      justifyContent: 'center',
    },
  })};
  @keyframes modal-slide-in {
    from {
      transform: translateY(100vh);
    }
    to {
      transform: translateY(0);
    }
  }
`;

const ModalContainer = styled.div<{ $fitContent: boolean }>`
  background: var(--background);
  border: 1px solid var(--border);
  box-shadow: 0 1.5rem 3rem var(--box-shadow);
  display: flex;
  flex-direction: column;
  max-height: 80%;
  max-width: 25rem;
  overflow: auto;
  width: 100%;
  border-radius: 1.25rem 1.25rem 0 0;
  ${(props) => responsiveStyleFromTheme({
    desktop: {
      borderRadius: '1.25rem',
      maxWidth: props.$fitContent
        ? '95%'
        : '25rem',
      width: props.$fitContent
        ? 'fit-content'
        : '100%',
    },
  })
};
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
  margin-bottom: 2rem;
  gap: 2rem;
`;

Modal.Body = ModalBody;

const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  padding: 2rem;
  button {
    width: 100%;
  }
`;

Modal.Footer = ModalFooter;

Modal.Heading = ({
  color = HeadingColors.Black,
  children,
}: {
  color?: HeadingColors,
  children: ReactNode | ReactNode[]
}) => (
  <Heading
    size={'md'}
    color={color}
  >
    {children}
  </Heading>
);

const ModalImage = styled.div`
  width: 6.875rem;
  height: 6.875rem;
  > img {
    width: 100%;
    height: 100%;
  }
`;

Modal.Image = ModalImage;

Modal.IconCircle = ({ children }: { children: ReactNode | ReactNode[] }) => (
  <IconCircle
    background={'var(--ghost)'}
    icon={children}
    circleSize={60}
  />
);

const ModalSubheading = styled(Paragraph)`
  color: var(--ash);
`;

Modal.Subheading = ({ children }: { children: ReactNode | ReactNode[] }) => (
  <ModalSubheading>{children}</ModalSubheading>
);

const ModalHeadingContainer = styled.div` 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  width: 100%;
`;

Modal.HeadingContainer = ModalHeadingContainer;
