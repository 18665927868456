import { useState } from 'react';
import styled from 'styled-components';
import { useModal } from '@lower-financial/core-components';
import Video1Thumbnail from '@lightspeed/assets/images/john-s-thumbnail.png';
import Video2Thumbnail from '@lightspeed/assets/images/brandi-t-thumbnail.png';
import Video3Thumbnail from '@lightspeed/assets/images/cory-w-thumbnail.png';
import Video4Thumbnail from '@lightspeed/assets/images/paul-m-thumbnail.png';
import Video1 from '@lightspeed/assets/videos/john-s.mp4';
import Video2 from '@lightspeed/assets/videos/brandi-t.mp4';
import Video3 from '@lightspeed/assets/videos/cory-w.mp4';
import Video4 from '@lightspeed/assets/videos/paul-m.mp4';
import { VideoModal } from '@lightspeed/components/ui/organisms/video-modal/video-modal';

interface VideoMapping {
  videoSrc: string;
  thumbnailImgSrc: string;
}

const videoMapArray: VideoMapping[] = [
  {
    thumbnailImgSrc: Video1Thumbnail,
    videoSrc: Video1,
  },
  {
    thumbnailImgSrc: Video2Thumbnail,
    videoSrc: Video2,
  },
  {
    thumbnailImgSrc: Video3Thumbnail,
    videoSrc: Video3,
  },
  {
    thumbnailImgSrc: Video4Thumbnail,
    videoSrc: Video4,
  },
];

export const TestimonialVideoGroup = () => {
  const [video, setVideo] = useState<VideoMapping | null>(null);
  const [shouldShowVideoModal, show, hide] = useModal();
  return (
    <>
      <VideoGroupContainer>
        {videoMapArray.map(({ videoSrc, thumbnailImgSrc }) => (
          <VideoCard key={videoSrc}>
            <LaunchVideoButton
              aria-label={'Launch customer review video.'}
              onClick={() => {
                setVideo({
                  thumbnailImgSrc,
                  videoSrc,
                });
                show();
              }}
            >
              <ThumbNail
                src={thumbnailImgSrc}
              />
            </LaunchVideoButton>
          </VideoCard>
        ))}
      </VideoGroupContainer>
      <VideoModal
        videoSrc={video?.videoSrc}
        thumbnailImgSrc={video?.thumbnailImgSrc}
        showModal={shouldShowVideoModal}
        onClose={hide}
      />
    </>
  );
};

const VideoGroupContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 48px;
  width: 100%;
  max-width: 80%;
  gap: 20px 40px;

  @media (max-width: 480px) {
    margin-top: 30px;
  }
`;

const VideoCard = styled.div`
  flex: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 173px;
  height: 300px;
  border-radius: 16px;
  box-shadow: 0 24px 48px var(--box-shadow);
`;

const LaunchVideoButton = styled.button`
  all: unset;
  cursor: pointer;
`;

const ThumbNail = styled.img`
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
`;
