import styled from 'styled-components';
import { ReactNode } from 'react';
import { CheckIcon as _CheckIcon } from '@lower-financial/icons';
import { Paragraph } from '../paragraph';

export function CheckBox({
  checked, onChange, id, label,
}: {
  checked: boolean,
  onChange: (newVal: boolean) => void,
  id: string,
  label: ReactNode | ReactNode[],
}) {
  return (
    <Container htmlFor={id}>
      <CheckboxContainer>
        <StyledCheckbox
          type={'checkbox'}
          id={id}
          data-testid={id}
          checked={checked}
          onChange={(e) => onChange(e.target.checked)}
        />
        {checked && (<CheckIcon color={'var(--success)'} />)}
      </CheckboxContainer>
      <Paragraph variant={'smallLight'}>{label}</Paragraph>
    </Container>
  );
}

const Container = styled.label`
  display: flex;
  margin: 8px 0;
  cursor: pointer;
`;

const CheckboxContainer = styled.div`
  position: relative;
`;

const CheckIcon = styled(_CheckIcon)`
  position: absolute;
  top: 6px;
  left: 5px;
  height: 8px;
  width: 10px;
`;

const StyledCheckbox = styled.input`
  flex-shrink: 0;
  appearance: none;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  border: 1px solid;
  border-color: var(--silk);
  cursor: pointer;
  vertical-align: middle;
  margin: 0 12px 0 0;
  
  &:active, &:focus {
    border-color: var(--primary);
  }
`;
