import { Banner, IconCircle, Paragraph } from '@lower-financial/core-components';
import { ShieldIcon } from '@lower-financial/icons';
import styled from 'styled-components';

const ShieldIconCircle = () => (
  <IconCircle
    background={'var(--white)'}
    circleSize={48}
    icon={<StyledShieldIcon color={'var(--tertiary-1)'} />}
  />
);

export function OnCallVerificationBanner() {
  const text = 'We’ll verify your identity on the call by asking questions based on the info you provided here.';
  return (
    <Banner
      backgroundColor={'var(--chalk)'}
      padding={'var(--spacing-4)'}
    >
      <BannerContent>
        <ShieldIconCircle />
        <div>
          <BannerHeading>
            On-Call Verification.
          </BannerHeading>
          <VerifyParagraph variant={'small'} >
            {text}
          </VerifyParagraph>
        </div>
      </BannerContent>
    </Banner>
  );
}

const BannerContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

const BannerHeading = styled(Paragraph)`
  font: var(--font-heading-5);
  font-size: smaller;
  letter-spacing: -0.04rem;
  margin-bottom: var(--spacing-1);
`;

const VerifyParagraph = styled(Paragraph)({
  alignItems: 'center',
  display: 'flex',
  gap: 8,
});

const StyledShieldIcon = styled(ShieldIcon)({
  height: '1.25rem',
  width: '1.25rem',
});
