import { ErrorResponse, JumpstartEligibilityStatus } from '@lower-financial/lending-web-api/generated';
import { fetch, TypedResponse } from '@lower-financial/toolbox';
import { ENV } from '@lightspeed/environment';

export function assessJumpstartEligibility(id: string) {
  return fetch<AssessJumpstartEligibilityResponse>(
    `${ENV.BACKEND_API_HOST}/api/lending/quoting_application_submissions/${id}/assess_jumpstart_eligibility`,
    {
      credentials: 'include',
      method: 'GET',
    },
  );
}

export type AssessJumpstartEligibilityResponse =
  | TypedResponse<JumpstartEligibilityStatus, 200>
  | TypedResponse<ErrorResponse, 404>;
