import { Banner, Paragraph } from '@lower-financial/core-components';
import { StopwatchIcon } from '@lower-financial/icons';
import styled from 'styled-components';

export const AverageWaitTimeBanner = () => (
  <Banner backgroundColor={'var(--tertiary-1-light)'}>
    <CallYouShortlyParagraph variant={'small'}>
      <StopwatchIcon /> Call you shortly! Avg wait: 15 mins.
    </CallYouShortlyParagraph>
  </Banner>
);

const CallYouShortlyParagraph = styled(Paragraph)({
  alignItems: 'center',
  display: 'flex',
  gap: 8,
});
