import { Banner, Heading, Paragraph } from '@lower-financial/core-components/src';
import { ReactNode } from 'react';
import styled from 'styled-components';

export const DidYouKnow = ({ children }: { children: ReactNode | ReactNode[] }) => (
  <Banner backgroundColor={'var(--tertiary-1-light)'}>
    <>
      <BannerHeading
        size={'xs'}
      >Did you know?
      </BannerHeading>

      <Paragraph variant={'smallLight'}>
        {children}
      </Paragraph>
    </>
  </Banner>
);

const BannerHeading = styled(Heading)`
  margin-bottom: var(--spacing-2);
`;

export const HighlightedText = styled.strong`
  color: var(--tertiary-1);
`;
