import { OptionalElement } from '@lower-financial/core-components/src';
import styled from 'styled-components';
import { SplitSection } from '@lightspeed/components/ui/templates/split-section';
import { ContentHeading } from '@lightspeed/components/shared/content-heading/content-heading';
import { DesktopButtonLayout } from '@lightspeed/components/shared/button-layout/desktop-button-layout';
import { PageLayoutProps } from '@lightspeed/components/shared/page-layout/page-layout';

export function DesktopPageLayout({
  title,
  subtitle,
  children,
  button,
  buttonDisclaimer,
  consentCheckbox,
  disclaimer,
  sidebar,
}: {
  title: PageLayoutProps['title'],
  subtitle?: PageLayoutProps['subtitle']
  children: PageLayoutProps['children'],
  button?: PageLayoutProps['button'],
  buttonDisclaimer?: PageLayoutProps['buttonDisclaimer'],
  consentCheckbox?: PageLayoutProps['consentCheckbox'],
  disclaimer?: PageLayoutProps['disclaimer'],
  sidebar?: PageLayoutProps['desktopSidebar'],
}) {
  return (
    <SplitSection>
      <SplitSection.Content>
        <DesktopContentContainer>
          <div>
            <ContentHeading
              title={title}
              subtitle={subtitle}
            />
            {children}
            <OptionalElement show={button !== undefined}>
              <DesktopButtonLayout
                button={button}
                disclaimer={buttonDisclaimer}
              />
            </OptionalElement>
          </div>
          <div>
            <OptionalElement show={disclaimer !== undefined}>
              {disclaimer}
            </OptionalElement>
            <OptionalElement show={consentCheckbox !== undefined}>
              {consentCheckbox}
            </OptionalElement>
          </div>
        </DesktopContentContainer>
      </SplitSection.Content>
      <DesktopSidebar>
        <OptionalElement show={sidebar !== undefined}>
          {sidebar}
        </OptionalElement>
      </DesktopSidebar>
    </SplitSection>
  );
}

const DesktopContentContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const DesktopSidebar = styled(SplitSection.Sidebar)`
  max-width: 22rem;
`;
