import { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Heading as _Heading, OptionalElement, Paragraph } from '@lower-financial/core-components';
import { useAnalytics } from '@lower-financial/analytics';
import { responsiveStyleFromTheme } from '@lower-financial/core-components/src/styles/utils/theme-utils';
import { theme } from '@lower-financial/core-components/src/styles/primary/theme';
import { useMediaQuery } from 'react-responsive';
import { Confetti } from '@lower-financial/icons';
import { AverageWaitTimeBanner } from '@lightspeed/components/shared/banners/average-wait-time';
import { Header as _Header } from '@lightspeed/components/ui/molecules/header';
import TrustPilotSVG from '@lightspeed/assets/images/trust-pilot.png';
import {
  TestimonialVideoGroup,
} from '@lightspeed/components/ui/organisms/testimonial-video-group/testimonial-video-group';
import { Layout } from '@lightspeed/components/ui/organisms/layout';
import { CardListCta, CardListCtaProps } from '@lightspeed/components/ui/organisms/card-list-cta';
import {
  usePartnerConfiguration,
} from '@lightspeed/contexts/partner-configuration-context/partner-configuration-context';
import { BasePageTemplate as _BasePageTemplate } from '@lightspeed/components/ui/templates/base-page-template';

const iconAnimationStyles = [
  {
    opacity: 0,
    transform: 'translateY(calc(var(--vh) * 40)) scale(0.2)',
  },
  {
    opacity: 1,
    transform: 'translateY(calc(var(--vh) * 25)) scale(1)',
    transition: 'opacity 700ms ease-in, transform 500ms ease-in',
  },
  {
    opacity: 1,
    transform: 'translateY(calc(var(--vh) * 25)) scale(1)',
    transition: 'transform 300ms linear',
  },
  {
    opacity: 1,
    transform: 'translateY(0) scale(1)',
    transition: 'opacity 300ms linear, transform 300ms linear',
  },
];

const animationStyles = [
  {
    opacity: 0,
    transform: 'translateY(calc(var(--vh) * 50))',
    transition: 'opacity 500ms, transform 500ms',
  },
  {
    opacity: 0,
    transform: 'translateY(calc(var(--vh) * 50))',
    transition: 'opacity 300ms linear, transform 300ms linear',
  },
  {
    opacity: 1,
    transform: 'translateY(calc(var(--vh) * 25))',
    transition: 'opacity 300ms ease-in, transform 300ms linear',
  },
  {
    opacity: 1,
    transform: 'translateY(0)',
    transition: 'opacity 300ms ease-in, transform 300ms linear',
  },
];

const cardsArray: CardListCtaProps['cards']
  = [{
    CtaLink: {
      linkText: 'Get the app',
      linkValue: 'https://www.lower.com/save',
    },
    CtaTitle: 'Save for a home faster.',
  }, {
    CtaLink: {
      linkText: 'Take the quiz',
      linkValue: 'https://www.lower.com/learn',
    },
    CtaTitle: 'Get instant home advice.',
  }];

export const TerminalScreenLayout = ({
  heading,
  subtext,
  iconComponent = null,
  productSpecificComponent = null,
  animations = true,
  showTestimonials = false,
  showWhileYoureWaitingCards = false,
  showAvgWaitTime = false,
  fireAnalyticsEvent,
  closeButtonUrl,
  callToAction,
  banner,
  showSiteFooter = true,
  showConfetti = false,
}: {
  heading: ReactNode | ReactNode[],
  subtext: string,
  iconComponent?: ReactNode | null,
  productSpecificComponent?: ReactNode | null,
  animations?: boolean,
  showTestimonials?: boolean,
  showWhileYoureWaitingCards?: boolean,
  showAvgWaitTime?: boolean,
  fireAnalyticsEvent: ReturnType<typeof useAnalytics>,
  closeButtonUrl?: string,
  callToAction?: ReactNode | ReactNode[],
  banner?: ReactNode | ReactNode[],
  showSiteFooter?: boolean,
  showConfetti?: boolean,
}) => {
  const [animationFrame, setAnimationFrame] = useState(0);
  const {
    partnerConfiguration: {
      isPartnerExperience, redirectUrl, displayName,
    },
  } = usePartnerConfiguration();

  const adjustedAnimationStyles = iconComponent !== null
    ? animationStyles
    : [animationStyles[1], animationStyles[3]];

  const isDesktop = useMediaQuery({
    query: `(min-width: ${theme.breakpoints[0]})`,
  });

  const shouldShowDoneButton = (isPartnerExperience || !!closeButtonUrl) && callToAction === undefined;

  useEffect(() => {
    setAnimationFrame((f) => f + 1);

    const timeout1 = setTimeout(() => setAnimationFrame((f) => f + 1), 1200);
    const timeout2 = setTimeout(() => setAnimationFrame((f) => f + 1), 1500);
    return () => {
      clearTimeout(timeout1);
      clearTimeout(timeout2);
    };
  }, []);

  const handleDone = () => {
    fireAnalyticsEvent('clicked_done');

    if (isPartnerExperience) {
      if (redirectUrl !== null) {
        window.location.href = redirectUrl;
      }
    } else if (closeButtonUrl) {
      window.location.href = closeButtonUrl;
    }
  };

  const doneButtonLabel = isPartnerExperience
    ? `Return to ${String(displayName)}`
    : 'Close';

  return (
    <Layout showSiteFooter={showSiteFooter}>
      <Header hideBackButton />
      <OptionalElement show={showConfetti}>
        <ConfettiContainer><Confetti /></ConfettiContainer>
      </OptionalElement>
      <BasePageTemplate>
        <TerminalContent>
          <OptionalElement show={iconComponent !== null}>
            <IconContainer
              style={{
                ...(animations
                  ? iconAnimationStyles[animationFrame]
                  : {}),
              }}
            >
              {iconComponent}
            </IconContainer>
          </OptionalElement>
          <MainContainer
            style={{
              ...(animations
                ? adjustedAnimationStyles[animationFrame]
                : {}),
            }}
          >
            <HeaderContainer>
              <Heading element={'h1'}>
                {heading}
              </Heading>
              <SubtextContainer>
                <Subtext
                  variant={
                    isDesktop
                      ? 'largeLight'
                      : 'light'
                  }
                >
                  {subtext}
                </Subtext>
              </SubtextContainer>
            </HeaderContainer>

            {productSpecificComponent}

            <OptionalElement show={showAvgWaitTime || banner !== undefined}>
              <BannerContainer>
                {showAvgWaitTime
                  ? <AverageWaitTimeBanner />
                  : banner
                }
              </BannerContainer>
            </OptionalElement>

            <OptionalElement show={shouldShowDoneButton}>
              <DoneButton onClick={handleDone}>{doneButtonLabel}</DoneButton>
            </OptionalElement>

            <OptionalElement show={callToAction !== undefined}>
              <ActionButtonContainer>
                {callToAction}
              </ActionButtonContainer>
            </OptionalElement>

            <OptionalElement show={!isPartnerExperience && showTestimonials}>
              <TrustPilotContainer>
                <img
                  src={TrustPilotSVG}
                  alt={'Trust Pilot rating'}
                />
              </TrustPilotContainer>

              <TestimonialVideoGroup />
            </OptionalElement>
            <OptionalElement show={!isPartnerExperience && showWhileYoureWaitingCards}>
              <WhileYoureWaitingContainer>
                <CardListCta
                  cards={cardsArray}
                  cardsTitle={'While you’re waiting...'}
                />
              </WhileYoureWaitingContainer>
            </OptionalElement>
          </MainContainer>
        </TerminalContent>
      </BasePageTemplate>
    </Layout>
  );
};

const Header = styled(_Header)`
  border-bottom: 1px solid var(--silk);
`;

const BasePageTemplate = styled(_BasePageTemplate)`
  margin: auto;
  flex-wrap: unset;
`;

const TerminalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
`;

const Heading = styled(_Heading)({
  textAlign: 'center',
});

const Subtext = styled(Paragraph)({
  marginTop: 'var(--spacing-6)',
  textAlign: 'center',
});

const SubtextContainer = styled.div`
  margin: auto;
  max-width: 30rem;
`;

const IconContainer = styled.div({
  margin: '0 auto',
});

const MainContainer = styled.div({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '1.5rem',
  width: '100%',
});

const HeaderContainer = styled.div`
  align-self: center;
  ${responsiveStyleFromTheme({
    desktop: {
      width: '50%',
    },
  })}
`;

const DoneButton = styled(Button)({
  marginTop: 'var(--spacing-12)',
  width: 240,
});

const ActionButtonContainer = styled.div`
  display: flex;
  gap: var(--spacing-9);
  margin-top: var(--spacing-12);
  ${responsiveStyleFromTheme({
    desktop: {
      flexDirection: 'row',
      gap: 'var(--spacing-10)',
    },
    mobile: {
      alignItems: 'center',
      flexDirection: 'column',
    },
  })}
`;

const TrustPilotContainer = styled.div({
  marginTop: 'var(--spacing-18)',
  textAlign: 'center',
});

const WhileYoureWaitingContainer = styled.div({
  marginTop: 'var(--spacing-10)',
});

const BannerContainer = styled.div`
  margin: var(--spacing-8) 0 0 0;
  max-width: 25rem;
`;

const ConfettiContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  > svg {
    position: absolute;
  }
`;
