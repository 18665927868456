import { LoadingButton } from '@lower-financial/core-components/src/components/loading-button';
import { theme } from '@lower-financial/core-components/src/styles/primary/theme';
import { ComponentProps } from 'react';
import { useMediaQuery } from 'react-responsive';

const defaultLabel = 'Next step';
const spinnerHeight = 'var(--font-button-md-size)';

export function NextStepButton({
  isLoading,
  disabled,
  onClick,
  label = defaultLabel,
  ariaLabel = label,
}: {
  isLoading: ComponentProps<typeof LoadingButton>['isLoading'],
  disabled: ComponentProps<typeof LoadingButton>['disabled'],
  onClick: ComponentProps<typeof LoadingButton>['onClick'],
  label?: string,
  ariaLabel?: ComponentProps<typeof LoadingButton>['ariaLabel'],
}) {
  const isDesktop = useMediaQuery({
    query: `(min-width: ${theme.breakpoints[0]})`,
  });

  return isDesktop
    ? (
      <LoadingButton
        width={'240px'}
        margin={'var(--spacing-4) 0'}
        label={label}
        ariaLabel={ariaLabel}
        isLoading={isLoading}
        disabled={disabled}
        onClick={onClick}
        spinnerHeight={spinnerHeight}
      />
    )
    : (
      <LoadingButton
        width={'100%'}
        marginTop={'2rem'}
        label={label}
        ariaLabel={ariaLabel}
        isLoading={isLoading}
        disabled={disabled}
        onClick={onClick}
        spinnerHeight={spinnerHeight}
      />
    );
}
