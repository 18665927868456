import styled from 'styled-components';
import { responsiveStyleFromTheme } from '@lower-financial/core-components/src/styles/utils/theme-utils';

export const FormRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.125rem;
  flex-direction: row;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
  margin: 1.125rem 0;
  ${responsiveStyleFromTheme({
    desktop: {
      margin: '1.5rem 0',
    },
  })}
`;

export const  FormHeadingRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100% ;
`;
