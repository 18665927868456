export const StopwatchIcon = ({ color = '#0064FF', ...props }) => (
  <svg
    xmlns={'http://www.w3.org/2000/svg'}
    width={'24'}
    height={'24'}
    viewBox={'0 0 24 24'}
    fill={'none'}
    {...props}
  >
    <title>Stopwatch</title>
    <path
      d={'M12 21C16.4183 21 20 17.4183 20 13C20 8.58172 16.4183 5 12 5C7.58172 5 4 8.58172 4 13C4 17.4183 7.58172 21 12 21Z'}
      stroke={color}
      strokeWidth={'2'}
      strokeMiterlimit={'10'}
      strokeLinecap={'round'}
      strokeLinejoin={'round'}
    />
    <path
      d={'M12 13V9'}
      stroke={color}
      strokeWidth={'2'}
      strokeLinecap={'round'}
      strokeLinejoin={'round'}
    />
    <path
      d={'M21 6L19 4'}
      stroke={color}
      strokeWidth={'2'}
      strokeLinecap={'round'}
      strokeLinejoin={'round'}
    />
    <path
      d={'M10 2L14 2'}
      stroke={color}
      strokeWidth={'2'}
      strokeLinecap={'round'}
      strokeLinejoin={'round'}
    />
  </svg>
);
