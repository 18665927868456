import { ComponentPropsWithoutRef, forwardRef, ReactNode } from 'react';
import styled from 'styled-components';
import { ThemedProps } from '../../styles/utils/theme-utils';

export interface TextInputStyledProps {
  $hasError?: boolean;
}

export type TextInputProps = {
  rightIcon?: ReactNode|ReactNode[]
} & ComponentPropsWithoutRef<'input'> & TextInputStyledProps;

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    { rightIcon, ...props }: TextInputProps,
    ref: React.ForwardedRef<HTMLInputElement>,
  ) => (
    <Container>
      <TextInputStyled
        ref={ref}
        {...props}
      />
      {rightIcon !== undefined && (
        <IconContainer>
          {rightIcon}
        </IconContainer>
      )}
    </Container>
  ),
);

const TextInputStyled = styled.input.attrs<TextInputStyledProps, TextInputStyledProps>(
  ({
    type = 'text',
    $hasError = false,
    onChange = () => {
      // do nothing
    },
    maxLength,
    ...props
  }) => ({
    ...props,
    $hasError,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      if (maxLength !== undefined) {
        e.target.value = e.target.value.slice(0, maxLength);
      }
      onChange(e);
    },
    type,
  }),
)`
  font: var(--font-paragraph-2);
  color: var(--input);
  height: 16px;
  letter-spacing: -0.04em;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px 20px;
  width: 100%;
  background: var(--background);
  border: 1px solid;
  border-color: var(--border);
  box-sizing: border-box;
  border-radius: 12px;

  transition: border ${ (props: ThemedProps) => props.theme.transitions.prop };

  // firefox specific padding for date input
  @-moz-document url-prefix() {
    &[type='date'] {
      padding: 15px 24px 33px 24px;
    }
  }

  ${({ $hasError, theme }: ThemedProps & TextInputStyledProps) => $hasError && `
    border: 1px solid;
    border-color: var(--error);
  `}

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1 + */
    color: var(--body-light);
    opacity: 1;
    /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10 - 11 */
    color: var(--body-light);
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--body-light);
  }

  &:hover {
  }

  &:disabled {
  }
`;

const Container = styled.div`
  position: relative;
`;

const IconContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  padding: 1rem;
`;
